import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Car Sharing App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/car-sharing-mobile-app/"
    metaDescription="Introducing Share Go, our mobile car sharing app design template. Customize, edit, and create your own car sharing app design today."
    description="
    h2:Share Go: A car sharing app design template for mobile
    <br/>
    Car sharing mobile app design just got a whole lot easier with Share Go, our car sharing app design template. This <a:https://uizard.io/templates/>UI template</a> is built to make your life easier and speed up your design flow. Stunning, minimalist, and chic, Share Go comes in hot blue color stylings with stunning aesthetics that are made to impress.
    <br/>
    h3:Build your car sharing app design rapidly
    <br/>
    It's time to put the pedal to the metal when it comes to mobile app design… Share Go, our mobile app design template, comes with all the must-have features and UI considerations baked in, making it super-fast to move your design straight to the showroom floor. Design more rapidly and <a:https://uizard.io/prototyping/>UX/UI prototype</a> faster than ever before.
    <br/>
    h3:Edit colors, add components, share your design
    <br/>
    You'll love Share Go as it is, but Uizard makes it so easy for you to add your own touches to your car sharing app design. You can add new screens, update calls to action, and even change the color scheme completely to match your own vision. If you feel like it, you can even add some go-faster stripes to really ensure your design hits fifth gear.
    "
    pages={[
      "A sleek and simplistic user registration screen",
      "A geolocation map mockup to demonstrate how your potential users will find their car share",
      "Car selector screen to highlight how your users would navigate through your app",
    ]}
    projectCode="XXJOrKmAzyC67GmPYya5"
    img1={data.image1.childImageSharp}
    img1alt="car sharing app design overview"
    img2={data.image2.childImageSharp}
    img2alt="car sharing app design car renting screen"
    img3={data.image3.childImageSharp}
    img3alt="car sharing app design map screen"
    img4={data.image4.childImageSharp}
    img4alt="car sharing app design unlock screen"
    img5={data.image5.childImageSharp}
    img5alt="car sharing app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/car-sharing-mobile-app/car-sharing-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/car-sharing-mobile-app/car-sharing-app-renting.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/car-sharing-mobile-app/car-sharing-app-map.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/car-sharing-mobile-app/car-sharing-app-unlock.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/car-sharing-mobile-app/car-sharing-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
